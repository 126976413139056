* {
  font-family: Calibri !important;
  font-size: 16px !important;
  outline: none !important;
}

html {
  background-color: #EEEEEE;
}

h1 {
  font-size:2em !important;
}
body { 
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bgGray {
  background-color: #f5f5f5;
}

.bgWhite {
  background-color: #FFF;
}

.list-group-item {
  border: none !important;
}

.list-inline-item{
  text-align: center;
}

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem white;
}

.tab-control {
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 1em !important;
}

/* .App {
  position: relative;  
  min-height: 100vh;
} */
@media (min-width:768px) {
  body {
    overflow-y:auto;
  }
}

@media(max-width:768px) {
  body {
    overflow-x: hidden;
  }
}

.container {
  padding-left:0; 
  padding-right:0;  
}

@media (max-width:1200px) {
  .container {
    padding-left: 3px;
    padding-right: 3px;
    width: 100% !important;
    max-width: 100% !important;
  }
}
@media (min-width:1201px) and (max-width:1440px) {
  .container {
    padding-left:0; padding-right:0;
  }
}

@media (min-width: 1140px) { 
  .container { 
    max-width: 100% !important; 
    padding-left:0;
    padding-right:0;
  } 
}
 
@media (min-width:1201px) {
  #nprogress .bar {
    background:  #DF346F;

    position: fixed;
    z-index: 1031;
    top: 50px !important;
    left: 0;

    width: 100%;
    height: 2px;
    display:none;
  }
  #nprogress .peg {
      box-shadow: 0 0 10px #DF346F, 0 0 5px #DF346F !important;
  }

  #nprogress .spinner-icon {
      border-top-color: #DF346F !important;
      border-left-color: #DF346F !important;
  }
}

.row {
  padding-left: 1%;
  padding-right: 1%;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 1em;
  margin-bottom: 1em;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-left: 0px !important;
  padding-right: 0px !important;  
}

.form-group {
  margin-right: 1em !important;
}

@media (max-width:1200px) {
  #nprogress .bar {
    background:  #DF346F;       
  }
  #nprogress .peg {
      box-shadow: 0 0 10px #DF346F, 0 0 5px #DF346F !important;
  }

  #nprogress .spinner-icon {
      border-top-color: #DF346F !important;
      border-left-color: #DF346F !important;
      display:none;
  }
}

#columnScrollable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

#columnScrollable::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  box-shadow:inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}

#columnScrollable::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.pointer {
  cursor: pointer;
}

.navbar {
  z-index: 5;
}

.tab-control .nav-tabs {
  border: none !important;
  position: relative;
  top: -6px;
}
.tab-control .nav-tabs .nav-item.show .nav-link, .tab-control .nav-tabs .nav-link.active {
  color: #FFF !important;
  background-color: #13cdfd !important;  
  border: none !important;     
  font-weight: bold;        
}

.tab-control .nav-link, .tab-control .nav-item {
  background-color: #EEEEEE;
}

.tab-control .nav-tabs .nav-link:focus, .tab-control .nav-tabs .nav-link:hover {
  border: none !important;
}

.tab-control .nav-link, .tab-control .nav-item { display: inline !important; outline:none !important;  border: none !important; cursor: pointer; }
.tab-control .nav-item:after {
  /* content: '>';    
  display:inline; */
}
.tab-control .nav-item:last-child::after {content: '';}

.circle {
  border-radius: 5px;
  max-width: 459.99px;
  height: 357px;
  background-color: #f5f5f5;
  position: relative;  
  cursor: pointer; 
}
.circle::after {
  content: " ";
  position: absolute;
  display: block;
  background-color: #fff;
  height: 20px;
  margin-top: -5px;
  top: 50%;
  left: 30px;
  right: 30px;
  z-index: 9;
}
.circle::before {
  content: " ";
  position: absolute;
  display: block;
  background-color: #fff;
  width: 20px;
  margin-left: -5px;
  left: 50%;
  top: 30px;
  bottom: 30px;
  z-index: 9;
}

#datePicker {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.poweredBy {
  margin-bottom: 0 !important;
  position: relative;
  bottom: 5px;
  font-style: italic;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  width: 100%;
  display: inline-block;
}

.pagination {
  margin-top: 10px;
}

.page-item.active .page-link {
  background-color:#13cdfd;
  border-color: #13cdfd;  
}

i {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  margin-left: 15px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

@media(max-width: 1200px) {
  .hideOnMobile {
    display: none !important;
  }

  .icon-line {
    position: relative;
    top: 25px;
  }
}

.react-numeric-input input {
  padding: 5px !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: .25rem !important;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
  padding-left: 15px !important;
  width: 100% !important;
}

.react-numeric-input i {
  display: none;
}

#stageContainer {
  width: 80%;
  margin-left: auto !important;
  margin-right: auto !important;
  /*background-color: #EEEE;*/
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media(max-width:1200px) {
  #stageContainer {
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.modal.left .modal-dialog {
	position:fixed;
	right: 0;
	margin: auto;
	width: 520px;
	height: 100%;
	-webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
}

@media(max-width: 1200px) {
  .modal.left .modal-dialog { 
    width: auto !important;
  }
}

.modal.left .modal-content {
	height: 100%;
	overflow-y: auto;
}

.modal.right .modal-body {
	padding: 0;
}

.modal.right.fade .modal-dialog {
	left: -320px;
	-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, left 0.3s ease-out;
	-o-transition: opacity 0.3s linear, left 0.3s ease-out;
	transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
	right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
	border-radius: 0;
	border: none;
}

.modal-header {
	border-bottom-color: #eeeeee;
	background-color: #fafafa;
}

#sidebarDetailSummary {
  z-index: 99999;
}

.modal-body {
  padding: 0 !important;
}

.modal-footer {
  background-color: #EEEEEE;
}

@media (max-width:1200px) { 
  .imageBox .hoverImg {    
    display: none;
    
  }

  .imageBox:hover .hoverImg {
      display: none;    
  }
}

@media (min-width:1201px) { 
  .imageBox {
    cursor: pointer;
  }

  .imageBox .hoverImg {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    
  }

  .imageBox:hover .hoverImg {
      display: inline-block;
      height: 100%;
      width: 100%;
  }
}
.page-link {
  color: #13cdfd !important;
}

.page-item.active .page-link {
  color: #FFF !important;
}

@media (min-width: 576px)
{
  .modal-dialog {
      width: 900px !important;      
  }

  .modal.left .modal-dialog {
    width: 900px !important;
  }
}